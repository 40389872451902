@use '@angular/cdk' as cdk;
@use './styles/cue/_colors' as *;
// Vendors
@use './styles/cue/_sizes' as sizes;

// Etc..
@use './styles/core/fonts';

@use 'sass:color';

@tailwind base;
@tailwind components;
@tailwind utilities;

// DO NOT IMPORT HERE KENDO - conflicts between bootstrap and kendo in VARIABLES!
// Theming

@include cdk.overlay();
.cdk-dialog-container {
  height: calc(100vh - 50px * 2) !important;
  overflow: auto;
  position: relative;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease-out;

  background-color: #fff;
  margin: 0px auto;

  @media (min-width: 576px) {
    max-width: 630px;
    min-width: 300px;
  }

  @media (min-width: 992px) {
    max-width: 800px;
    min-width: 900px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
    min-width: 1000px;
  }

  @media screen and (max-width: 767.5px) {
    max-height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)) !important;

    max-width: 100vw !important;
    width: 100vw !important;
  }
}

$padding-popup: 50px;

:root {
  --cue-icon-color: #{$magenta-500};
}

cue-icon {
  --icon-color: var(--cue-icon-color, var(--assist-color_primary-500));
}

.my-user-icon {
  fill: var(--assist-color_common_orange);
}

.my-portal-icon {
  fill: var(--assist-primary);
}

.my-start-icon {
  fill: var(--assist-color_common_orange);
}

.my-finish-icon {
  fill: var(--assist-primary);
}

body {
  height: 100%;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 4px;
    background-color: #e5e5e5;
  }

  &::-webkit-scrollbar {
    -webkit-box-shadow: none;
    border-radius: 4px;
    background-color: #e5e5e5;
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none;
    border-radius: 4px;
    background-color: var(--brand-300);

    &:hover {
      background-color: var(--brand-500);
    }
  }
}

@media screen and (min-width: 767.5px) {
  *::-webkit-scrollbar {
    -webkit-box-shadow: none;
    border-radius: 4px;
    background-color: #e5e5e5;
    width: 8px;
    height: 8px;
  }
}

body,
html {
  scroll-behavior: smooth;
}

.svg-fix {
  height: calc(100vh - #{sizes.$top-nav-height * 3} - #{sizes.$page-padding * 2});
}

:focus {
  outline: none;
}

.svg-map {
  background-color: $color_map;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
}

div.reservation-modal > div.modal-body quill-editor p > strong {
  font-weight: 700;
}

.button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  user-select: none;
  line-height: 1.5;
  border: 0px;

  &.primary {
    color: $color_common_white;
    background-color: $color_common_orange;
  }
}

.layer-blue {
  background-color: $color_common_main;
  color: $color_common_white;

  a {
    color: $color_common_white;
  }
}

.layer-white {
  background-color: $color_common_white;
  color: $color_common_main;
}

.layer-lightgray {
  background-color: $color_left-menu;
  color: $color_common_main;
}

.layer-orange {
  background-color: $color_common_orange;
  color: $color_common_white;
}

.form-control {
  height: unset;
}

body,
nav-root {
  overflow-x: hidden;
  // HACK Na scroll to refresh
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-y: hidden;
  height: -webkit-fill-available;
}

body,
html,
nav-root {
  overflow: hidden !important;
  font-size: 14px;

  button,
  p {
    font-size: 14px;
  }
}

body {
  transform: translate3d(0, 0, 0);
  z-index: 1;
  background-color: #233c5a !important;
  height: -webkit-fill-available;
}

html {
  background-color: #233c5a !important;
  height: -webkit-fill-available;
}

nav-root {
  z-index: 2;
  background-color: #fff;
}

.card {
  border: 0;
  border-radius: 0;
}

.modal-content {
  .modal-header {
    button {
      border: none;
      margin: 0;
      padding: 0;
      height: 24px;
      width: 24px;
      position: relative;

      &:hover {
        cue-icon {
          --icon-color: #000;
        }
      }

      cue-icon {
        --icon-color: rgba(0, 0, 0, 0.7);
        transition: all 0.2s ease;
        position: relative;
      }
    }
  }

  .modal-footer {
    .btn-secondary {
      box-shadow: none !important;
    }

    .btn-delete {
      color: $color_common_white;
      background-color: $color_button_red;
    }
  }
}

@media screen and (max-width: 767.5px) {
  .layer-lightgray {
    background-color: lightgray;
  }
}

kendo-popup {
  z-index: 1000000;
}

div.auto-height {
  .modal-content {
    height: auto;
  }
}

kendo-popup {
  z-index: 3000 !important;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  @media screen and (max-width: 767.5px), (max-height: 450px) {
    height: calc(100vh - 150px);
  }
}

.item-tescted-text {
  display: flex;
  align-items: center;

  .image-wrapper {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-right: 6px;

    img {
      max-width: 40px;
      max-height: 40px;
    }
  }
}

$switch-circle-width: 21px;

.kendo-switch-wrapper {
  width: 50%;
  @media screen and (max-width: 767.5px) {
    width: 100%;
  }

  kendo-switch {
    .k-switch-container {
      .k-switch-handle {
        width: $switch-circle-width;
        height: $switch-circle-width;
      }

      .k-switch-label-on,
      .k-switch-label-off {
        width: 100%;
        padding: 0 3px;
        transition: all 0.2s ease;
        font-size: 8px;
        margin-top: 1px;
      }

      .k-switch-label-off {
        text-align: right;
      }
    }

    &.k-switch-on {
      .k-switch-handle {
        left: calc(100% - #{$switch-circle-width}) !important;
      }
    }
  }
}

$range-slider-offset: 16px;

kendo-rangeslider {
  width: calc(100% - #{$range-slider-offset}) !important;
  margin-left: calc(#{$range-slider-offset} / 2);
}

$input-margin: 3px;

quill-editor {
  display: block;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.35);
  margin: $input-margin;
  width: calc(100% - #{$input-margin} * 2);

  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border: none;
  }

  .ql-container {
    border-top: 1px solid #c2c2c2 !important;
    font-size: 14px;
    color: #000;
    font-family: 'Nunito', sans-serif !important;
    font-weight: 400;
  }
}

@media screen and (max-width: 767.5px) {
  .k-draghandle {
    width: 25px;
    height: 25px;
  }
}

.legend-item {
  margin-right: 30px;
  display: inline-block;
}

.legend-item-rect {
  height: 20px;
  display: inline-block;
  width: 20px;
  margin-right: 10px;
}

.legend-item-text {
  line-height: 20px;
  height: 20px;
  display: inline-block;
}

.navbar-nav .dropdown-menu {
  position: absolute !important;
}

cue-button button:disabled {
  opacity: 0.5;
}

kendo-floatinglabel {
  width: 100% !important;
}

.k-switch {
  width: unset !important;
  padding: 0 !important;
}

.k-i-close {
  background: transparent !important;
  color: $color_common_white !important;
  visibility: visible !important;
  opacity: 1 !important;
  transition: all 0.2s ease;
}

.k-state-focused .k-i-close {
  border-top: 1px solid $color_common_main;
  border-bottom: 1px solid $color_common_main;
}

.favourites-wrapper {
  padding: 15px;
}

.more {
  text-align: right;
  font-size: 18px;

  &.mobile {
    display: none;
  }

  @media screen and (max-width: 767.5px) {
    text-align: center;
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }

  a {
    color: $color_common_main;
    font-weight: bold;

    &:hover {
      text-decoration: none;

      img {
        margin-left: 20px;
      }
    }

    img {
      position: relative;
      top: -1px;
      margin-left: 6px;
      transition: all 0.25s ease;
    }
  }
}

cue-icon.clear {
  svg {
    stroke: var(--assist-negative);
  }
}

.input-calendar-container {
  width: 100%;
  position: relative;

  &.has-icon {
    input.k-textbox {
      padding-left: 40px;
    }

    cue-icon.input-icon {
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  cue-icon.clear {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    z-index: 1;
    --icon-color: var(--assist-negative);
    margin-top: 1px;
    font-size: 14px;
    padding: 10px;
  }

  input.k-textbox {
    background: $color_common_white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 13px 15px;
    width: 100%;
    height: unset;
    border: 2px solid transparent;
    transition: all 0.25s ease;

    &:focus {
      border-color: color.adjust($color_common_main, $lightness: 60%);

      & + cue-icon.input-icon {
        color: $color_common_main !important;
        --icon-color: $color_common_main !important;
      }
    }

    &.has-clear {
      padding-right: 40px;
    }
  }

  cue-icon {
    transition: all 0.25s ease;
  }
}

$kendo-expansionpanel-line-offset: 20px;

kendo-expansionpanel {
  border: none !important;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: $kendo-expansionpanel-line-offset;
    width: calc(100% - #{$kendo-expansionpanel-line-offset} * 2);
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
  }

  &.k-state-focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .k-expander-header {
    padding: 5px 0;
    border-radius: 10px;

    .k-expander-indicator {
      display: none;
    }
  }
}

.cue-input {
  &.k-textbox,
  &.k-textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba($color_common_main, 0.5);
    transition: all 0.25s ease;
    box-shadow: none;

    &.ng-invalid.ng-touched {
      border-color: var(--assist-negative);
    }

    &.k-state-focused,
    &.k-state-focus {
      border-width: 2px;
    }

    textarea {
      min-height: 90px;
    }

    input,
    textarea {
      font-size: 14px;
      color: $color_common_black;
      font-weight: 400;
      line-height: 1.6;
    }
  }
}

.input-group {
  position: relative;

  textarea.resizeable-textarea {
    min-height: 52px;
    color: #000;
    font-size: 14px;
    outline: none;
    //width: calc(100% - #{$input-margin} * 2);
    padding: 4px 8px;
    resize: none;
    overflow: hidden;
    background: var(--assist-color_common_white) !important;
    color: var(--assist-color_common_black) !important;
    width: 100% !important;
    transition: all 0.2s ease;
    border-radius: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1) !important;
    border: 1px lightgray solid;

    &:focus {
      border: 1px solid var(--assist-color_common_main);
    }

    &.ng-invalid {
      border-color: var(--assist-negative);
    }

    &.has-clear {
      padding-right: 30px;
    }
  }

  .clear {
    position: absolute;
    top: 8px;
    right: 11px;
    cursor: pointer;
    color: var(--assist-negative);
    transition: all 0.25s ease;
    @media screen and (max-width: 767.5px) {
      font-size: 20px;
      top: 4px;
      &.quill-clear {
        top: 40px;
      }
    }

    &.quill-clear {
      top: 45px;
    }

    &:hover {
      color: color.adjust($negative, $lightness: -20%);
    }
  }
}

quill-editor.cue-input {
  background: var(--assist-color_common_white) !important;
  color: var(--assist-color_common_black) !important;
  width: 100% !important;
  transition: all 0.2s ease;
  border-radius: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1) !important;
  border: 1px lightgray solid !important;

  &.ng-invalid {
    border-color: var(--assist-negative) !important;
  }

  &:focus,
  &:focus-within {
    border-color: var(--assist-color_common_main) !important;
  }
}

.center {
  text-align: center;
}

.button-light {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  color: $color_common_main;
  padding: 8px 30px;
  transition: all 0.25s ease;
  cursor: pointer;

  cue-icon {
    --icon-color: var(--assist-positive);
    transition: all 0.25s ease;
    margin-right: 8px;
  }

  &:hover {
    color: $color_common_white;
    background: var(--assist-positive);

    cue-icon {
      --icon-color: $color_common_white;
    }
  }
}

// Remove when kendo is compiled again.
.k-checkbox:checked {
  background-color: var(--brand-500);
}

.data-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  margin-bottom: 18px;
  @media screen and (max-width: 570px) {
    flex-direction: column;
  }

  label {
    width: 116px;
    min-width: 116px;
    margin-right: 6px;
    margin-bottom: 0;
    color: $color_common_main;
    font-family: 'Nunito' !important;
    white-space: -moz-pre-wrap; /* Mozilla */
    white-space: -hp-pre-wrap; /* HP printers */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: pre-wrap; /* CSS 2.1 */
    white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
    word-wrap: break-word; /* IE */
    word-break: break-all;
    @media screen and (max-width: 570px) {
      margin-bottom: 6px;
      width: 100%;
    }
  }

  .value {
    color: $color_common_black;
    font-weight: bold;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
  }

  .input-wrapper {
    width: 100%;
    position: relative;

    kendo-textbox,
    kendo-textarea,
    .k-checkbox {
      position: relative;
      top: -4px;
    }

    .input-description {
      font-size: 12px;
      color: $description-gray;
      margin: 0;
      font-weight: 400;
      margin-top: 10px;

      &:nth-of-type(1) {
        margin-top: 10px;
        @media screen and (max-width: 570px) {
          margin-top: 4px;
        }
      }
    }

    .input-error {
      font-size: 12px;
      color: var(--assist-negative);
      margin: 0;
      font-weight: 400;

      &:nth-of-type(1) {
        margin-top: 10px;
        @media screen and (max-width: 570px) {
          margin-top: 4px;
        }
      }
    }

    textarea.resizeable-textarea {
      min-height: 52px;
      color: #000;
      font-size: 14px;
      outline: none;
      //width: calc(100% - #{$input-margin} * 2);
      padding: 4px 8px;
      resize: none;
      overflow: hidden;
      background: var(--assist-color_common_white) !important;
      color: var(--assist-color_common_black) !important;
      width: 100% !important;
      transition: all 0.2s ease;
      border-radius: 10px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1) !important;
      border: 1px lightgray solid;

      &:focus {
        border: 1px solid var(--assist-color_common_main);
      }

      &.ng-invalid {
        border-color: var(--assist-negative);
      }

      &.has-clear {
        padding-right: 30px;
      }
    }

    input.text-input {
      color: #000;
      font-size: 14px;
      outline: none;
      //width: calc(100% - #{$input-margin} * 2);
      padding: 4px 8px;
      resize: none;
      overflow: hidden;
      background: var(--assist-color_common_white) !important;
      color: var(--assist-color_common_black) !important;
      width: 100% !important;
      transition: all 0.2s ease;
      border-radius: 10px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1) !important;
      border: 1px lightgray solid;

      &:focus {
        border: 1px solid var(--assist-color_common_main);
      }

      &.ng-invalid {
        border-color: var(--assist-negative);
      }

      &.has-clear {
        padding-right: 30px;
      }
    }

    .clear {
      position: absolute;
      top: 6px;
      right: 11px;
      cursor: pointer;
      color: var(--assist-negative);
      transition: all 0.25s ease;
      @media screen and (max-width: 767.5px) {
        font-size: 20px;
        top: 6px;
        &.quill-clear {
          top: 40px;
        }
      }

      &.quill-clear {
        top: 45px;
      }

      &:hover {
        color: color.adjust($negative, $lightness: -20%);
      }
    }
  }
}

.cue-dropdown-image-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .image-wrapper {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin-right: 6px;
    overflow: hidden;

    &.xl {
      width: 46px;
      height: 46px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  span {
    font-weight: normal;
  }
}

.k-tooltip-wrapper {
  max-width: 220px;

  .k-callout {
    margin-top: -6px !important;
    color: $color_common_main;
  }

  .k-tooltip {
    color: $color_common_white;
    background: $color_common_main;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  }
}

.k-i-close-circle {
  color: var(--assist-negative) !important;
  transition: all 0.25s ease;
}

.scrolling-effect {
  position: relative;
  overflow-y: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
    width: 25px;
    pointer-events: none;
    height: 100%;
  }
}

.loading-wrapper {
  display: flex;
  height: 100dvh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  font-size: 4rem;
  padding: 15px;
  line-height: 1;
  background-color: #ffffff;

  & img {
    max-height: 12rem;
  }
}
